<template>
<div>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
    <v-layout
      justify-center
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          color="green"
          title="Торговые автоматы"
          text=""
        >
          <v-data-table
            :headers="headers"
            :items="items"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td style="max-width=40px">
                <v-btn title="Подробнее к мэппингу" color="#1867c0!important" small flat icon :to="{ name: 'Торговый автомат', params: { id: item.id } }" >
                  <v-icon>
                    mdi-alert-circle-outline
                  </v-icon>
                </v-btn>
                <v-btn title="Меню" color="#808080!important" small flat icon :to="{ name: 'Меню торгового автомата', params: { id: item.id } }" >
                  <v-icon>
                    mdi-format-list-bulleted
                  </v-icon>
                </v-btn>
                <v-btn title="Редактировать торговый автомат" color="primary" small flat icon @click="pickToEditMachine(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <!-- <v-btn title="Копировать торговый автомат" color="warning" small flat icon @click="pickToCopyMachine(item)">
                  <v-icon>mdi-file-multiple</v-icon>
                </v-btn> -->
                <v-btn title="Копировать мэппинг" color="primary" small flat icon @click="pickToCopyMapping(item)">
                  <v-icon>mdi-file-multiple</v-icon>
                </v-btn>
                <v-btn title="Удалить торговый автомат" color="error" small flat icon @click="pickToDeleteMachine(item)" >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
                
              </td>
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.temperature }}</td> 
              <td>{{ item.serialVendingNumber }}</td>
              <td>{{ getPlaceName(item.placeId) }}</td>
              <td>{{ item.type }} </td>
              <td>{{ item.condition }} </td>
              <td>{{ item.lastConnectionTime | date }} {{ item.lastConnectionTime | time }}</td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
      
    </v-layout>
  </v-container>
  <v-container fluid>
    <v-layout row wrap>
        <v-btn title="Добавить новый автомат" color="#66bb6a" @click="addMachineDialog = true">Добавить</v-btn>
    </v-layout>
  </v-container>
  <v-dialog v-model="addMachineDialog" max-width="600">
      <v-card>
          <v-toolbar flat color="#66bb6a">
            <v-toolbar-title left  color="blue" class="text--white">Новый торговый автомат</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="addMachineDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid">
                        
                        
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Название</v-flex>
                  <v-flex xs8>
                      <v-text-field :rules="rules.required"
                                    label="Название в панели администратора" 
                                    v-model="newVendingMachine.name" 
                                    required
                                    ></v-text-field>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Местоположение торгового автомата</v-flex>
                  <v-flex xs8>
                      <v-text-field :rules="rules.required"
                                    label="Местоположение отображается в приложении пользователя" 
                                    v-model="newVendingMachine.description" 
                                    required
                                    ></v-text-field>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Серийный номер терминала</v-flex>
                  <v-flex xs8>
                      <v-textarea :rules="rules.required"
                                  label="Описание" 
                                  v-model="newVendingMachine.serialNumber"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Ключ терминала</v-flex>
                  <v-flex xs8>
                      <v-textarea :rules="rules.required"
                                  label="Введите ключ от ридера" 
                                  v-model="newVendingMachine.blockchainKey"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Серийный номер автомата</v-flex>
                  <v-flex xs8>
                      <v-textarea :rules="rules.required"
                                  label="Серийный номер автомата" 
                                  v-model="newVendingMachine.serialVendingNumber"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Номер венданалитики</v-flex>
                  <v-flex xs8>
                      <v-textarea
                                  label="Серийный номер автомата" 
                                  v-model="newVendingMachine.vendAnaliticaId"></v-textarea>
                  </v-flex>
              </v-layout>

              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Тип</v-flex>
                  <v-flex xs8>
                      <v-select label="Выберите тип автомата"
                                :items="types"
                                :rules="rules.required"
                                v-model="newVendingMachine.type"></v-select>
                  </v-flex>
              </v-layout>

              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Состояние</v-flex>
                  <v-flex xs8>
                      <v-select label="Выберите состояние"
                                :items="conditions"
                                :rules="rules.required"
                                v-model="newVendingMachine.condition"></v-select>
                  </v-flex>
              </v-layout>

              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Точка продажи</v-flex>
                  <v-flex xs8>
                      <v-select label="Выберите точку продажи"
                                :items="places"
                                item-text="name"
                                item-value="id"
                                :rules="rules.required"
                                v-model="newVendingMachine.placeId"></v-select>
                  </v-flex>
              </v-layout>

              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Количество кофейных ячеек</v-flex>
                  <v-flex xs8>
                      <v-text-field 
                                  label="Количество кофейных ячеек" 
                                  v-model="newVendingMachine.coffeeCellsAmount"></v-text-field>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Количество снековых А ячеек</v-flex>
                  <v-flex xs8>
                      <v-text-field 
                                  label="Количество снековых A ячеек" 
                                  v-model="newVendingMachine.snackACellsAmount"></v-text-field>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Количество снековых B ячеек</v-flex>
                  <v-flex xs8>
                      <v-text-field 
                                  label="Количество снековых B ячеек" 
                                  v-model="newVendingMachine.snackBCellsAmount"></v-text-field>
                  </v-flex>
              </v-layout>

              <v-layout wrap>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" @click="addMachine" class="white--text" :disabled="!valid">Сохранить</v-btn>
                  <v-btn color="red darken-1" @click.native="addMachineDialog = false" class="white--text">Отмена</v-btn>
              </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
  </v-dialog>
  <v-dialog v-model="copyMachineDialog" max-width="600">
      <v-card>
          <v-toolbar flat color="#66bb6a">
            <v-toolbar-title left color="blue" class="text--white">Редактировать торговый автомат</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="copyMachineDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid">
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Название</v-flex>
                  <v-flex xs8>
                      <v-text-field :rules="rules.required"
                                    label="Название в панели администратора" 
                                    v-model="copyVendingMachine.name" 
                                    ></v-text-field>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Местоположение торгового автомата</v-flex>
                  <v-flex xs8>
                      <v-text-field :rules="rules.required"
                                    label="Местоположение отображается в приложении пользователя" 
                                    v-model="copyVendingMachine.description" 
                                    required
                                    ></v-text-field>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Серийный номер терминала</v-flex>
                  <v-flex xs8>
                      <v-textarea :rules="rules.required"
                                  label="Серийный номер устройства" 
                                  v-model="copyVendingMachine.serialNumber"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Ключ терминала</v-flex>
                  <v-flex xs8>
                      <v-textarea 
                                  label="Введите ключ от терминала" 
                                  v-model="copyVendingMachine.blockchainKey"></v-textarea>
                  </v-flex>
              </v-layout>
               <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Серийный номер автомата</v-flex>
                  <v-flex xs8>
                      <v-textarea :rules="rules.required"
                                  label="Серийный номер автомата" 
                                  v-model="copyVendingMachine.serialVendingNumber"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Номер венданалитики</v-flex>
                  <v-flex xs8>
                      <v-textarea
                                  label="Серийный номер в венданалитике" 
                                  v-model="copyVendingMachine.vendAnaliticaId"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Тип</v-flex>
                  <v-flex xs8>
                      <v-select label="Выберите тип автомата"
                                :items="types"
                                :rules="rules.required"
                                v-model="copyVendingMachine.type"></v-select>
                  </v-flex>
              </v-layout>

              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Состояние</v-flex>
                  <v-flex xs8>
                      <v-select label="Выберите состояние"
                                :items="conditions"
                                :rules="rules.required"
                                v-model="copyVendingMachine.condition"></v-select>
                  </v-flex>
              </v-layout>

              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Точка продажи</v-flex>
                  <v-flex xs8>
                      <v-select label="Выберите точку продажи"
                                :items="places"
                                item-text="name"
                                item-value="id"
                                :rules="rules.required"
                                v-model="copyVendingMachine.placeId"></v-select>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" @click="copyMachine" class="white--text" :disabled="!valid">Сохранить</v-btn>
                  <v-btn color="red darken-1" @click.native="copyMachineDialog = false" class="white--text">Отмена</v-btn>
              </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
  </v-dialog>
  <v-dialog v-model="editMachineDialog" max-width="600">
      <v-card>
          <v-toolbar flat color="#66bb6a">
            <v-toolbar-title left color="blue" class="text--white">Редактировать торговый автомат</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="editMachineDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid">
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Название</v-flex>
                  <v-flex xs8>
                      <v-text-field :rules="rules.required"
                                    label="Название в панели администратора" 
                                    v-model="editVendingMachine.name" 
                                    ></v-text-field>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Местоположение торгового автомата</v-flex>
                  <v-flex xs8>
                      <v-text-field
                        :rules="rules.required"
                        label="Местоположение отображается в приложении пользователя" 
                        v-model="editVendingMachine.description" 
                        required
                        ></v-text-field>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Серийный номер терминала</v-flex>
                  <v-flex xs8>
                      <v-textarea :rules="rules.required"
                                  label="Серийный номер устройства" 
                                  v-model="editVendingMachine.serialNumber"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Ключ терминала</v-flex>
                  <v-flex xs8>
                      <v-textarea 
                                  label="Введите ключ от терминала" 
                                  v-model="editVendingMachine.blockchainKey"></v-textarea>
                  </v-flex>
              </v-layout>
               <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Серийный номер автомата</v-flex>
                  <v-flex xs8>
                      <v-textarea :rules="rules.required"
                                  label="Серийный номер автомата" 
                                  v-model="editVendingMachine.serialVendingNumber"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Номер венданалитики</v-flex>
                  <v-flex xs8>
                      <v-textarea 
                                  label="Серийный номер в венданалитике" 
                                  v-model="editVendingMachine.vendAnaliticaId"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Тип</v-flex>
                  <v-flex xs8>
                      <v-select label="Выберите тип автомата"
                                :items="types"
                                :rules="rules.required"
                                v-model="editVendingMachine.type"></v-select>
                  </v-flex>
              </v-layout>

              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Состояние</v-flex>
                  <v-flex xs8>
                      <v-select label="Выберите состояние"
                                :items="conditions"
                                :rules="rules.required"
                                v-model="editVendingMachine.condition"></v-select>
                  </v-flex>
              </v-layout>

              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Точка продажи</v-flex>
                  <v-flex xs8>
                      <v-select label="Выберите точку продажи"
                                :items="places"
                                item-text="name"
                                item-value="id"
                                :rules="rules.required"
                                v-model="editVendingMachine.placeId"></v-select>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" @click="editMachine" class="white--text" :disabled="!valid">Сохранить</v-btn>
                  <v-btn color="red darken-1" @click.native="editMachineDialog = false" class="white--text">Отмена</v-btn>
              </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
  </v-dialog>
  <v-dialog v-model="deleteMachineDialog" max-width="600">
      <v-card>
          <v-toolbar flat color="#66bb6a">
            <v-toolbar-title left color="blue" class="text--white">Удаление торгового автомата</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="deleteMachineDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        <v-card-text>
          <v-layout wrap>
            Вы действительно хотите удалить {{ editVendingMachine.name }}?
          </v-layout>
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" @click="deleteMachine" class="white--text" :disabled="!valid">Удалить</v-btn>
            <v-btn color="red darken-1" @click.native="deleteMachineDialog = false" class="white--text">Отмена</v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
  </v-dialog>
  <v-dialog v-model="copyMappingDialog" max-width="600">
      <v-card>
          <v-toolbar flat color="#66bb6a">
            <v-toolbar-title left color="blue" class="text--white">Копирование мэппинга торгового автомата</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="copyMappingDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        <v-card-text>
          <v-layout wrap>
            Вы действительно хотите скопировать мэппинг из {{ sourceVendingMachine.name }}? В выбранном автомате мэппинг будет полностью заменен.<br><br>

            Выберите автомат, в который будет скопирован мэппинг:
          </v-layout>
          <v-layout wrap>
            <v-form ref="form" v-model="valid">
              <v-autocomplete
                label="Выберите автомат"
                v-model="targetVendingMachineId"
                :items="vendingMachinesToCopy"
                item-text="name"
                item-value="id"
                
                :rules="rules.required"
              >
              </v-autocomplete>
            </v-form>
          </v-layout>
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" @click="copyMapping" class="white--text" :disabled="!valid">Скопировать</v-btn>
            <v-btn color="red darken-1" @click.native="copyMappingDialog = false" class="white--text">Отмена</v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
  </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    addMachineDialog: false,
    copyMachineDialog: false,
    editMachineDialog: false,
    deleteMachineDialog: false,
    copyMappingDialog: false,
    headers: [
      {
        sortable: false,
        text: ''
      },
      {
        sortable: true,
        text: 'Название',
        value: 'name'
      },
      {
        sortable: true,
        text: 'Описание',
        value: 'description'
      },
      {
        sortable: true,
        text: 'Температура',
        value: 'temperate'
      },
      {
        sortable: true,
        text: 'Серийный номер',
        value: 'serialVendingNumber'
      },
      {
        sortable: true,
        text: 'Точка продаж',
        value: 'placeId'
      },
      {
        sortable: true,
        text: 'Тип',
        value: 'type'
      },
      {
        sortable: true,
        text: 'Состояние',
        value: 'condition'
      },
      {
        sortable: true,
        text: 'Был онлайн',
        value: 'lastConnectionTime'
      }
    ],
    items: [],
    places: [],
    rules: {
        required: [v => !!v || 'Поле обязательно',],
    },
    types: ['Снековый автомат', 'Кофейный автомат'],
    conditions: ['Исправный ТА','ТА в состоянии ошибки','ТА с заканчивающимися ингредиентами', 'Отсоединенный'],
    newVendingMachine: {
      description: null,
      serialNumber: null,
      serialVendingNumber: null,
      blockchainKey: null,
      name: null,
      type: null,
      condition: null,
      vendAnaliticaId: null,
      placeId: -1,
      coffeeCellsAmount: 0,
      snackACellsAmount: 0,
      snackBCellsAmount: 0
    },
    copyVendingMachine: {
      name: null,
      description: null,
      serialNumber: null,
      serialVendingNumber: null,
      blockchainKey: null,
      type: null,
      condition: null,
      vendAnaliticaId: null,
      placeId: -1
    },
    editVendingMachine: {},
    sourceVendingMachine: {},
    targetVendingMachineId: -1
  }),
  computed:{
    vendingMachinesToCopy(){
      if (this.sourceVendingMachine){
        let sourceId = this.sourceVendingMachine.id
        return this.items.filter(function(item, index, arr){
            return item.id != sourceId;
        });
      }
      return this.items
    }
  },
  mounted () {
    this.getPlaces()
    this.getMachines()
  },
  methods: {
    pickToEditMachine(item){
      this.editVendingMachine = item;
      this.editMachineDialog = true;
    },
    pickToCopyMachine(item){
      this.sourceVendingMachine = item;
      this.copyMachineDialog = true;
    },
    pickToCopyMapping(item){
      this.sourceVendingMachine = item;
      this.targetVendingMachineId = -1;
      this.copyMappingDialog = true;
    },
    pickToDeleteMachine(item){
      this.editVendingMachine = item;
      this.deleteMachineDialog = true;
    },
    getMachines (){
      this.$http.get('/vendingmachines')
        .then(response => {
          this.items = response.data
          console.log(this.items)
        })
    },
    getPlaces (){
      this.$http.get('/places')
        .then(response => {
          this.places = response.data
          console.log(this.items)
        })
    },
    addMachine(){
      this.$http.post('/vendingmachines', this.newVendingMachine).
        then(response => {
          this.addMachineDialog = false;
          this.getMachines();
        }).
        catch(e => {
            console.log(e)
        });
    },
    copyMachine(){
      this.$http.post('/vendingmachines/' + this.sourceVendingMachine.id + '/copy', this.copyVendingMachine).
          then(response => {
            this.copyMachineDialog = false;
            this.getMachines();
          }).
          catch(e => {
              console.log(e)
          });
    },
    copyMapping(){
      if (this.targetVendingMachineId && 
        this.sourceVendingMachine && 
        this.targetVendingMachineId != this.sourceVendingMachine.id
        )
      {
        this.$http.post('/vendingmachines/' + this.sourceVendingMachine.id + '/items/copy/' + this.targetVendingMachineId).
        then(response => {
          this.copyMappingDialog = false;
          this.getMachines();
        }).
        catch(e => {
            console.log(e)
        });
      }
      else{
        this.copyMappingDialog = false;
      }
    },
    editMachine(){
      this.$http.put('/vendingmachines/'+ this.editVendingMachine.id, this.editVendingMachine).
        then(response => {
          this.editMachineDialog = false;
          //this.getMachines();
        }).
        catch(e => {
            console.log(e)
        });
    },
    deleteMachine(){
      this.$http.delete('/vendingmachines/' + this.editVendingMachine.id).
        then(response => {
          this.deleteMachineDialog = false
          this.editVendingMachine = {};
          this.getMachines();
        }).
        catch(e => {
            console.log(e)
        });
    },
    getPlaceName(id){
      return this.places.find(x=>x.id == id).name
    }
  }
}
</script>
